<template>
<div>

  <div class="container-fluid wrapper-medium mt-5">
    <div class="row">
        <div class="col-12">
            <h1 class="anim-load1">
                Frequently Asked Questions
            </h1>
            <b-link class="btn btn-link" to="/"><span class="icon icon-left-open mr-2"></span>Back to Home</b-link>
        </div>
    </div>
  </div>

  <div class="container-fluid wrapper-medium mt-5 mb-5">
    <div class="row">
      <div class="col-12 col-md-10">
        <h2 style="text-decoration:underline">How to use TAYC-R</h2>
        <div class="accordion">
          <h2 class="h3 accordion-title" v-b-toggle.collapse-0>How do I use TAYC-R? <span class="icon-up-open"></span></h2>
          <b-collapse id="collapse-0" class="mt-2 accordion-content">
            <img src="/static/img/how-to-use-taycr.png" alt="How to use taycr"/>
      
          </b-collapse>
        </div>

        <div class="accordion">
          <h2 class="h3 accordion-title" v-b-toggle.collapse-2>How do I add a new administrator to the organisational account? <span class="icon-up-open"></span></h2>

            <b-collapse id="collapse-2" class="mt-2 accordion-content">
              <p>When setting up your organisational account Adoptionplus will only create one profile for an
              administrator within your organisation. After this is set, you will have the facility to add more
              account administrators if you wish. Account administrators will be able to create both new
              administrator and therapist profiles.</p>
              <p>Within your dashboard select the ‘Manage your organisation members’ tab on the top right of
              the page.</p>
            <img src="/static/img/manage-org-members.png" style="width:40%" alt="How to use taycr"/>

              <p>Within the ‘Members Management’ screen, select ‘Invite new member’.</p>
              <p>Simply fill in the details and select the ‘Organisation Admin’ tick box before sending the invite.
              This person will then have all admin privileges when they have set up their account and will be
              able to create further administrator accounts.</p>
              <p>Any new Organisation Admin will also have therapist privileges. They will have the ability to create family profiles and send out therapeutic questionnaires.</p>
            </b-collapse>
          </div>

         <div class="accordion">
          <h2 class="h3 accordion-title" v-b-toggle.collapse-3>How do I add a new therapist to the organisational account? <span class="icon-up-open"></span></h2>
          <b-collapse id="collapse-3" class="mt-2 accordion-content">
                 
            <p>Only those with ‘Organisation Admin’ privileges will be able to invite new therapists to create
            an account.</p>
            <p>Within the dashboard select ‘Manage your organisation members’ tab on the top right of the
            page.</p>
            <img src="/static/img/manage-org-members.png" style="width:40%" alt="How to use taycr"/>

            <p>Within the ‘Members Management’ screen, select ‘Invite new member’.</p>
            <p>Fill in the details but <strong>do not</strong> select the ‘Organisation Admin’ tick box before sending the invite if
you wish to create an account with therapist privileges <strong>only</strong>.</p>
<p>Once the newly invited therapist has set up their account, they will then be able to create family
profiles and send out therapeutic questionnaires. They <strong>will not</strong> be able to invite new admins or
therapists to create an account.</p>
          </b-collapse>
        </div>

        <div class="accordion">
          <h2 class="h3 accordion-title" v-b-toggle.collapse-4>How do I filter my caseload?<span class="icon-up-open"></span></h2>
          <b-collapse id="collapse-4" class="mt-2 accordion-content">
            <p>All families will be listed in your dashboard by parent first name/second name and child first
                name. There is a search function in which you can free type the name of the family you wish to
                find and only matching results will be displayed.</p>
          </b-collapse>
        </div>

         <div class="accordion">
          <h2 class="h3 accordion-title" v-b-toggle.collapse-5>When emailing an assessment to a client will they see my email address?<span class="icon-up-open"></span></h2>
          <b-collapse id="collapse-5" class="mt-2 accordion-content">
           <p>No. The parent will receive the questionnaire from the generic, automated email address within
the site; noreply@adoptionplus.co.uk</p>
          </b-collapse>
        </div>

          <div class="accordion">
          <h2 class="h3 accordion-title" v-b-toggle.collapse-6>Will I receive a copy of the analysis report via email as soon as soon as the parent has
completed it?<span class="icon-up-open"></span></h2>
          <b-collapse id="collapse-6" class="mt-2 accordion-content">
              <p>Yes. As soon as a parent has completed their therapeutic questionnaire a copy will be sent
directly to the email address you provide when setting up your account.</p>
          </b-collapse>
        </div>

        <div class="accordion">
          <h2 class="h3 accordion-title" v-b-toggle.collapse-7>How do I keep track of the date I sent a client an assessment and when they took the
assessment?<span class="icon-up-open"></span></h2>
          <b-collapse id="collapse-7" class="mt-2 accordion-content">
              <p>On your therapist dashboard you will have a searchable list of all the parent/child records you
have created. By clicking on a name, you will enter this family’s record. Within the record will be
a chronological list of all the questionnaires you have sent this family. When clicking into the
record for each questionnaire you will be able to view the dates for when it was both created
and completed.</p>
          </b-collapse>
        </div>

          <div class="accordion">
          <h2 class="h3 accordion-title" v-b-toggle.collapse-8>Can I export the report from the TAYC-R site?<span class="icon-up-open"></span></h2>
          <b-collapse id="collapse-8" class="mt-2 accordion-content">
              <p>There is currently no functionality to enable exporting of data from the TAYC-R site. You will
however receive a unique URL for every therapeutic questionnaire completed which can be
shared by email. You will be able to view all questionnaire results within your dashboard,
including answer to specific questions and data plotted on a graph.</p>
          </b-collapse>
        </div>

        <div class="accordion">
          <h2 class="h3 accordion-title" v-b-toggle.collapse-9>How do I purchase new credits?<span class="icon-up-open"></span></h2>
          <b-collapse id="collapse-9" class="mt-2 accordion-content">
              <p>Simply contact Adoptionplus’ administration team at <a href="mailto:taycr@adoptionplus.co.uk"> taycr@adoptionplus.co.uk</a> to advise on how many credits you
would like to purchase. They will then provide you with an invoice for the required amount. Once
the payment has been completed your account will be updated with the requested number of
credits and an email notification sent.</p>
          </b-collapse>
        </div>

        <div class="accordion">
            <h2 class="h3 accordion-title" v-b-toggle.collapse-10>What if parents have questions or concerns about completing TAYC-R?<span class="icon-up-open"></span></h2>
          <b-collapse id="collapse-10" class="mt-2 accordion-content">
              <p>We advise taking some time with parents before inviting them to complete the therapeutic
questionnaire to ensure they feel comfortable with the process. You may consider explaining
they will receive an email with a link, enabling them to input answers and that they can seek
advice directly from yourself if they have any issues or concerns while filling out the
questionnaire.</p>
          </b-collapse>
        </div>

        <div class="accordion">
          <h2 class="h3 accordion-title" v-b-toggle.collapse-11>What if parents have questions about their report?<span class="icon-up-open"></span></h2>
          <b-collapse id="collapse-11" class="mt-2 accordion-content">
              <p>We advise taking some time with parents before sending them the results of their therapeutic
questionnaire, to explain the ways in which it may be of use to them in understanding their
family relationships, but also managing expectations about the extent of its functionality.</p>
          </b-collapse>
        </div>
          <div class="accordion">
          <h2 class="h3 accordion-title" v-b-toggle.collapse-12>How can I delete my account?<span class="icon-up-open"></span></h2>
          <b-collapse id="collapse-12" class="mt-2 accordion-content">
              <p>Please follow this <a href="/account-settings">link</a> to delete your account.</p>
          </b-collapse>
        </div>

        <h2 style="text-decoration:underline;padding-top:20px;padding-bottom:10px;">General Information</h2>
          <div class="accordion">
            <h2 class="h3 accordion-title" v-b-toggle.collapse-13>What is TAYC-R?<span class="icon-up-open"></span></h2>
            <b-collapse id="collapse-13" class="mt-2 accordion-content">
                <p>Research shows that if the parent/child relationship is loving, reciprocal, warm, rewarding and close, this
  is a foundation for children to thrive, develop and even recover from any early adversity.</p>
  <p>Conceived and used widely in the field of adoption and fostering over the last twenty years, the first
  incarnation of the TAYC-R showed itself helpful to both professionals and parents alike. In collaboration
  with the Rees Centre at Oxford University, Adoptionplus has sought to improve this valuable tool to make
  it more reliable, more easily interpretable and easier to use.</p>
  <p>TAYC-R provides a quick guide to highlight what is going well or what may be becoming challenging and
  stressful. This can then help identify areas that other more specific clinical assessments can investigate
  further</p>
  <p>TAYC-R can identify if a relationship needs attention, and from there the origins of these difficulties can
  be explored in more detail.</p>
            </b-collapse>
        </div>

        <div class="accordion">
            <h2 class="h3 accordion-title" v-b-toggle.collapse-14>Who has access to my personal Information?<span class="icon-up-open"></span></h2>
            <b-collapse id="collapse-14" class="mt-2 accordion-content">
                <p>You are the only one who has full access to your personal information. We do not collect your full name or address. The only identifiable data we capture is your email address. This information is stored by our third party processor and is not seen by anyone undertaking the analysis.  You are the only one to have access to your personal data. No one else sees it unless you choose to share it.  For more information, please see our privacy policy.</p>
            </b-collapse>
        </div>
        <div class="accordion">
            <h2 class="h3 accordion-title" v-b-toggle.collapse-15>How is my data protected?<span class="icon-up-open"></span></h2>
            <b-collapse id="collapse-15" class="mt-2 accordion-content">
                 <p>You are the only one who has full access to your personal information. We do not collect your full name or address. Adoptionplus do not know which information belongs to which family. </p>
            <p>The only identifiable data we capture is your email address. This is stored separately from your responses to the therapeutic questionnaire and used to invite you to complete the service on a regular basis if you choose to sign up. This information is stored by our third party processor and is not seen by anyone undertaking the analysis.
            No personal identifying information is shared with local authorities, regional adoption agencies, social workers or health services. </p>
            <p>You are the only one to have access to your personal data. No one else sees it unless you choose to share it.  For more information, please see our <b-link to="/privacy-policy" class="link">privacy policy</b-link>.
            </p>
            </b-collapse>
        </div>
      </div>
    </div>
  </div>

  <Footer />

</div>
</template>

<script>
export default {
  name: 'Faq',
  data() {
    return {
    }
  },
  created: function () {
    this.$store.state.loadingProgress = false
    this.$store.state.appSection = 'brochure'
  }
}
</script>
